import React from 'react';
import { Link } from 'react-router-dom';
import { FormattedMessage } from '../../util/reactIntl';
import { isFieldForListingType } from '../../util/fieldHelpers';

import { Heading } from '../../components';

import css from './ListingPage.module.css';

const SectionDetailsMaybe = props => {
  const { publicData, metadata = {}, listingFieldConfigs, isFieldForCategory, intl } = props;

  if (!publicData || !listingFieldConfigs) {
    return null;
  }

  const pickListingFields = (filteredConfigs, config) => {
    const { key, schemaType, enumOptions, showConfig = {} } = config;
    const listingType = publicData.listingType;
    const isTargetListingType = isFieldForListingType(listingType, config);
    const isTargetCategory = isFieldForCategory(config);

    const { isDetail, label } = showConfig;
    const publicDataValue = publicData[key];
    const metadataValue = metadata[key];
    const value = publicDataValue || metadataValue;

    if (isDetail && isTargetListingType && isTargetCategory && typeof value !== 'undefined') {
      const findSelectedOption = enumValue => enumOptions?.find(o => enumValue === `${o.option}`);
      const getBooleanMessage = value =>
        value
          ? intl.formatMessage({ id: 'SearchPage.detailYes' })
          : intl.formatMessage({ id: 'SearchPage.detailNo' });
      const optionConfig = findSelectedOption(value);

      switch (schemaType) {
        case 'enum':
          return filteredConfigs.concat({ key, value: optionConfig?.label, label });
        case 'boolean':
          return filteredConfigs.concat({ key, value: getBooleanMessage(value), label });
        case 'text':
          return filteredConfigs.concat({ key, value, label });
        default:
          return filteredConfigs.concat({ key, value, label });
      }
    }
    return filteredConfigs;
  };

  const existingListingFields = listingFieldConfigs.reduce(pickListingFields, []);

  return existingListingFields.length > 0 ? (
    <section className={css.sectionDetails}>
      <Heading as="h2" rootClassName={css.sectionHeading}>
        <FormattedMessage id="ListingPage.detailsTitle" />
      </Heading>
      <ul className={css.details}>
        {existingListingFields.map(detail => {
          if (detail.key === 'public_tag' && typeof detail.value === 'string') {
            const tags = detail.value.split(',').filter(tag => tag.trim());
            return (
              <li key={detail.key} className={css.detailsRow}>
                <span className={css.detailLabel}>{detail.label}</span>
                <div className={css.tagsList}>
                  {tags.map((tag, index) => (
                    <Link
                      key={`tag-${index}`}
                      to={`/s?keywords=${encodeURIComponent(tag)}`}
                      className={css.inlineTag}
                    >
                      #{tag}
                      {index < tags.length - 1 && ' '}
                    </Link>
                  ))}
                </div>
              </li>
            );
          } else {
            return (
              <li key={detail.key} className={css.detailsRow}>
                <span className={css.detailLabel}>{detail.label}</span>
                <span>{detail.value}</span>
              </li>
            );
          }
        })}
      </ul>
    </section>
  ) : null;
};

export default SectionDetailsMaybe;
